<template>
  <!-- 网络日志 -->
  <div ref="networkLog" class="networkLog">
    <div ref="tabs" class="tabs">
      <el-tabs v-model="form.channelNo" type="card" @tab-click="handleClick">
        <el-tab-pane
          v-for="(item, index) in channelList"
          :key="index"
          :label="item.channelName"
          :name="item.channelNo"
        ></el-tab-pane>
      </el-tabs>
    </div>
    <div ref="ActionBar" class="ActionBar">
      <div class="actionBar-box">
        <div class="col-1">请求时间段：</div>
        <div style="max-width: none" class="col-2">
          <el-date-picker
            v-model="timeValue"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          ></el-date-picker>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">接口名称：</div>
        <div class="col-2">
          <el-select
            v-model="form.interfaceNames"
            multiple
            placeholder="请选择"
          >
            <el-option
              v-for="item in listData"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">请求用户：</div>
        <div class="col-2">
          <el-input v-model="form.userName" clearable></el-input>
        </div>
      </div>
      <div class="actionBar-box">
        <div class="col-1">用户类型：</div>
        <div class="col-2">
          <el-select v-model="form.clientType" placeholder="请选择" clearable>
            <el-option
              v-for="item in userTypeList"
              :key="item"
              :label="item"
              :value="item"
            ></el-option>
          </el-select>
        </div>
      </div>
      <div class="actionBar-box">
        <el-button type="primary" size="small" @click="queryFun"
          >查询</el-button
        >
        <el-button type="primary" size="small" @click="exportFun"
          >导出</el-button
        >
      </div>
    </div>
    <Table
      :table-height="tableHeight"
      :col-class-type="true"
      :table-data="tableData"
      :title-name="titleName"
      :operation="true"
    >
      <template slot-scope="scope">
        <el-button type="text" size="small" @click="seeBtn(scope.scopeRow)"
          >查看</el-button
        >
      </template>
    </Table>
    <div ref="pagination" class="pagination">
      <el-pagination
        background
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        :page-size="form.pageSize"
        :current-page="form.currentPage"
        @size-change="onSizeChange"
        @current-change="onCurrentChange"
      ></el-pagination>
    </div>
    <el-dialog :visible.sync="referShow" title="查看">
      <div class="referlist">
        <div v-for="(item, index) in referList" :key="index" class="refer">
          <label>{{ item.title }}：</label>
          <p>{{ item.value }}</p>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  pageGatewayLogApi,
  getInterfaceNameListApi,
  getInterfaceNameListExportApi,
  getCurrentUserChannelInfoAPI,
} from "@/api/lib/api.js";
export default {
  data() {
    return {
      referShow: false,
      referList: [],
      tableHeight: 0,
      total: 0,
      timeValue: null,
      userTypeList: ["后台管理用户", "司机端用户"],
      tableData: [],
      titleName: [
        {
          title: "访问实例",
          props: "targetServer",
        },
        {
          title: "接口名称",
          props: "interfaceName",
        },
        {
          title: "请求路径",
          props: "requestPath",
        },
        {
          title: "请求方法",
          props: "requestMethod",
        },
        {
          title: "请求协议",
          props: "schemaType",
        },
        {
          title: "请求体",
          props: "requestBody",
        },
        {
          title: "响应体",
          props: "responseData",
        },
        {
          title: "请求时间",
          props: "requestDate",
        },
        {
          title: "响应时间",
          props: "responseDate",
        },
        {
          title: "用户端类型",
          props: "clientType",
        },
        {
          title: "请求用户",
          props: "userName",
        },
        {
          title: "请求用户信息",
          props: "loginCommon",
        },
      ],
      listData: [],
      form: {
        startDate: "", //请求开始时间
        endDate: "", //请求结束时间
        interfaceNames: [], //接口名称集合
        userName: "", //请求用户
        clientType: "", //用户端类型
        pageSize: 10, //每页大小
        currentPage: 1, //当前页
        channelNo: "",
      },
      channelList: [],
    };
  },
  mounted() {
    // 初始化最近7天的时间数据
    this.timeValue = [
      this.GMTToStr(new Date(new Date().getTime() - 3600 * 1000 * 24 * 7)),
      this.GMTToStr(new Date()),
    ];
    this.getQueryChannelList();
    this.renderList();
    this.$nextTick(() => {
      this.computeHeight();
      window.addEventListener("resize", this.computeHeight, false);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.computeHeight);
  },
  methods: {
    //获取渠道数据
    getQueryChannelList() {
      getCurrentUserChannelInfoAPI().then((res) => {
        if (res.code == "SUCCESS") {
          this.channelList = res.data;
          this.form.channelNo = res.data[0]?.channelNo;
          this.renderData();
        }
      });
    },
    handleClick(tab) {
      this.form = {
        startDate: "", //请求开始时间
        endDate: "", //请求结束时间
        interfaceNames: [], //接口名称集合
        userName: "", //请求用户
        clientType: "", //用户端类型
        pageSize: 10, //每页大小
        currentPage: 1, //当前页
        channelNo: tab.name,
      };
      this.renderData();
    },
    // 渲染表格方法
    renderData() {
      this.form.startDate = this.GMTToStr(new Date(this.timeValue[0]));
      this.form.endDate = this.GMTToStr(new Date(this.timeValue[1]));
      pageGatewayLogApi(this.form)
        .then((res) => {
          if (res.code == "SUCCESS") {
            this.tableData = res.data.list;
            this.total = res.data.total;
          }
        })
        .catch((ref) => {
          this.$message.error(ref.msg);
        });
    },
    // 获取接口名称数据方法
    renderList() {
      getInterfaceNameListApi().then((res) => {
        if (res.code == "SUCCESS") {
          this.listData = res.data;
        }
      });
    },
    // 点击查询按钮
    queryFun() {
      this.form.currentPage = 1;
      if (this.timeValue) {
        this.renderData();
      } else {
        this.$message.error("请填写请求时间段");
      }
    },
    // 点击导出按钮
    exportFun() {
      let exportObj = this.deepClone(this.form);
      exportObj.pageSize = this.total;
      this.renderData();
      getInterfaceNameListExportApi(exportObj).then((res) => {
        const blob = new Blob([res], { type: "application/vnd.ms-excel" });
        const fileName = "网络日志 " + this.GMTToStr(new Date()) + ".xlsx"; //文件名
        const linkNode = document.createElement("a");

        linkNode.download = fileName; //a标签的download属性规定下载文件的名称
        linkNode.style.display = "none";
        linkNode.href = URL.createObjectURL(blob); //生成一个Blob URL
        document.body.appendChild(linkNode);
        linkNode.click(); //模拟在按钮上的一次鼠标单击

        URL.revokeObjectURL(linkNode.href); // 释放URL 对象
        document.body.removeChild(linkNode);
      });
    },
    // 当前页码改变
    onCurrentChange(page) {
      this.form.currentPage = page;
      this.renderData();
    },

    // 当前条数改变
    onSizeChange(size) {
      this.form.pageSize = size;
      this.form.currentPage = 1;
      this.renderData();
      this.computeHeight();
    },
    // 计算表格高度
    computeHeight() {
      const wholeHeight = this.$refs.networkLog.clientHeight;
      const ActionBarHeight = this.$refs.ActionBar.clientHeight;
      const tabs = this.$refs.tabs.clientHeight;
      this.tableHeight = wholeHeight - 100 - ActionBarHeight - tabs;
    },
    seeBtn(obj) {
      this.referShow = true;
      this.referList = this.titleName;
      this.referList.forEach((e) => {
        e.value = obj[e.props];
      });
    },
  },
};
</script>

<style scoped lang="scss">
/deep/.el-date-editor .el-range-separator {
  line-height: 25px;
}
.networkLog {
  box-shadow: 0 0 4px 0 rgba($color: #343434, $alpha: 0.1);
  border-radius: 4px;
  padding: 16px 20px;
  background: #ffffff;
  height: calc(100% - 32px);
  .ActionBar {
    background: #f7f8f9;
    padding: 16px;
    padding-bottom: 0;
    display: flex;
    margin-bottom: 16px;
    flex-flow: wrap;
    .actionBar-box:nth-child(4n) {
      margin-right: 0;
    }
    .actionBar-box:after {
      content: "";
      flex: auto;
    }
    .actionBar-box {
      width: calc(25% - 13px);
      height: 32px;
      line-height: 32px;
      margin-right: 14px;
      margin-bottom: 16px;
      display: flex;
      .col-1 {
        width: 84px;
        display: inline-block;
      }
      .col-2 {
        max-width: 210px;
        width: calc(100% - 84px);
        .el-select {
          width: 100%;
        }
      }
    }
  }
}
.referlist {
  padding: 10px;
  .refer {
    display: flex;
    margin: 20px 0;
    label {
      width: 100px;
      flex-shrink: 0;
      font-weight: bold;
      text-align: right;
    }
    p {
      flex: 1;
      line-height: 20px;
    }
  }
}
</style>
